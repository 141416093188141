import * as Popper from "@popperjs/core";
import Alpine from "alpinejs";
import isWebPSupported from "is-webp-supported";
import { screens } from "tailwindcss/defaultTheme";
import Axios from "axios";
import "../css/site.css";

window.Alpine = Alpine;
window.Popper = Popper;
window.axios = Axios;
window.supportsWebp = isWebPSupported();

document.addEventListener("alpine:init", () => {
    Alpine.store("passwordValidator", {
        email: "",
        password: "",
        validPassword: false,
    });
    Alpine.store("showRegisterForm", {
        on: false,
        toggle() {
            this.on = true;
        },
    });
});

/**
 *
 * Find out if a tailwind screen value matches the current window
 *
 * @param {string} screen
 *
 * @return {Object|Boolean}
 */
window.screenIs = (screen = "") => {
    const matches = Object.entries(screens).reduce((results, [name, size]) => {
        const mediaQuery =
            typeof size === "string"
                ? `(min-width: ${size})`
                : `(max-width: ${size.max})`;

        results[name] = window.matchMedia(mediaQuery).matches;

        return results;
    }, {});

    if (screen === "") {
        return matches;
    }

    if (!screens[screen]) {
        console.error(`No match for "${screen}"`);

        return false;
    }

    return matches[screen];
};
Alpine.start();
